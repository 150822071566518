import React from 'react';
import "./header-text.css"
import {useTypingText} from "../../type_writer/use-type-writer";
const HeaderText = () => {
    const { word } = useTypingText(
        ["إعداد دراسات الجدوى", "إستشارات تطوير الأعمال", "التدريب والتنمية البشرية"],
        130,
        20
    );
    return (
        <div className="typing">
            <h1> نحن نقوم بـ {word}</h1>
        </div>
    );
};

export default HeaderText;
