import React from 'react';
import "./moral-section.css"
import morals from "../../assets/icons/morals.svg"
const MoralItem = ({title,details}) => {
    return (
        <div className="card4">
           <div className="title">
               <img src={morals} alt="moral"/>
               <h3>
                   {title}
               </h3>
           </div>
            <p>{details}</p>
            <div className="dimmer"></div>
            <div className="go-corner" href="#">
                <div className="go-arrow"></div>
            </div>
        </div>
    );
};

export default MoralItem;
