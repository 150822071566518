import React from 'react';
import "./why-us.css"
import SingleTypewriter from "../../components/type_writer/single-writer";
const WhyUs = () => {
    return (
        <div id="why-us" className='why-us'>
            <h1 className="center-text">
                لماذا نحن؟
                <div className="under-line"></div>
            </h1>
            <h2 className="right primary">نوفر استشارات لتطوير اعمال الشركات</h2>
            <ul className='list'>
                <li>
                    <span className='circle'></span>
                    لتوجيهك إلى الطرق المختصرة وآلية العمل والمعرفة الغير موجودة بمؤسستك لتوفر الجهد والوقت والمال.
                </li>
                <li>
                    <span className='circle'></span>
                    لتقديم خدمات محترفة ومتخصصة ليست متوفرة لديك في فريق العمل
                </li>
                <li>
                    <span className='circle'></span>
                    لإعطائك الحلول الذكية لتحديات العمل التي تواجهها
                </li>
                <li>
                    <span className='circle'></span>
                    لتقديم الأفكار الجديدة التي تساعدك على تطوير عملك.
                </li>
                <li>
                    <span className='circle'></span>
                    لتقييم عملك من وجهة نظر حيادية وأمينة.
                </li>
                <li>
                    <span className='circle'></span>
                    التحليل وتشخيص المشاكل من منظور مختلف لا يراه فريق عملك والمدراء.
                </li>
                <li>
                    <div className='circle'>{" "}</div>
                    لتوفر على نفسك مبالغ طائلة من خلال طرح حلول المشكلات وتحقيق الأرباح المجدية.
                </li>
            </ul>
            <h2 className="right primary">استشارات لزيادة حجم المبيعات</h2>
            <div>
                <div className='more-sales'>
                    <p>
                        زيادة المبيعات تحتاج إلى سلسلة من الإجراءات تبدأ من التخطيط الصحيح والمدروس ثم تطبيق ذلك على ارض الواقع من خلال السوق، خطط الإدارة واعادة ترتيب الهيكل الاداري وضع الأهداف الحقيقية، الربط الوطيد بأدوات التسويق والخطة التشغيلية والتدريب والإشراف الصحيح على كل ما سبق، للتعرف على اسباب انخفاض مبيعاتك والحلول المناسبة التي تختصر عليك الجهد والوقت والمال واختيار الحل الأنسب
                    </p>
                </div>
            </div>
        </div>
    );
};

export default WhyUs;
