import React, {useRef} from 'react';
import "./our-values.css"
import vision from "../../assets/icons/values/1.svg"
import message from "../../assets/icons/values/2.svg"
import goal from "../../assets/icons/values/3.svg"
const OurValues = () => {
    const valuesList = [
        {
            icon:<img className="icon"
                      src={vision}
                      alt="icon" />,
            title: "رؤيتنا",
            details:"أن نكون شركة رائدة في دولة قطر والأسواق الإقليمية ، في مجال تقديم الاستشارات العامة ، واستشارات تطوير الأعمال ، والتنمية البشرية"
        },
        {
            icon:<img className="icon"
                      src={message}
                      alt="icon" />,
            title: "رسالتنا",
            details:"تقديم أسس تجارية استثمارية في مجال الاستشارات والتدريب والإدارة وتطوير الأعمال، لإنشاء وإدارة المشاريع الاستثمارية وتوفير أعلى المعايير لخدمة العملاء"
        },
        {
            icon:<img className="icon"
                      src={goal}
                      alt="icon" />,
            title: "هدفنا",
            details:"خدمة العملاء وتقديم أفضل الخدمات على أعلى المستويات، والوصول لأعلى درجة من اعتمادية العملاء علينا"
        },
        {
            icon:<img className="icon"
                      src={vision}
                      alt="icon" />,
            title: "قيمنا",
            details:"نؤمن بأن كل عميل له خصوصية ينفرد بها عن غيره وبناء على ذلك لا يمكن ان يكون هناك نهج واحد يناسب الجميع بهذه السياسة نحن نتعامل مع عملائنا"
        },
    ]
    const section = useRef(null)
    const mouseMove = (e) => {
        var rect = e.currentTarget.getBoundingClientRect();
        section.current.style.setProperty('--x',(e.clientX - rect.left)+'px');
        section.current.style.setProperty('--y',(e.clientY - rect.top)+'px');
    }
    return (
        <div className="our-values">
            <section ref={section} onMouseMove={mouseMove}>
                {valuesList.map((value,index)=> (
                    <div key={index} className="box">
                        {value.icon}
                        <div className="box">
                            <h3>{value.title}</h3>
                            <p>{value.details}</p>
                        </div>
                    </div>
                ))}
            </section>
        </div>
    );
};

export default OurValues;
