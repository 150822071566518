import React from 'react';
import "./header.css"
import HeaderText from "./header-text/header-text";
import logo from "../../assets/logo/protocol-logo.svg"
const Header = () => {
    return (
        <>
            <div className="header">
                <img src={logo} alt="logo"/>
                {/*<h1>بروتوكول</h1>*/}
                {/*<h3>- للإستشارات والتدريب -</h3>*/}
                <HeaderText />
                {/*<button className="btn-order">طلب خدمة</button>*/}
            </div>
            <div className="area">
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </>
    );
};

export default Header;
