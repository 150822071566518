import React from 'react';
import BlobImage from "../../components/blob-image/blob-image";
import "./about-us.css"
const AboutUs = () => {
    return (
        <div id="aboute" className="about-us">
            <div className="image">
                <BlobImage />
            </div>
            <div className="details">
                <h1>
                    عن الشركة
                    <div className="under-line"></div>
                </h1>
                <p>
                    تعمل بروتوكول ضمن شبكة كبيرة من الشركاء العالميين في مجال التجارة لامداد عملائها وزبائنها بكافة المعينات وبأسعار في المتناول ولدينا الامكانية بتوفير كل ما تحتاجون اليه
                </p>
            </div>
        </div>
    );
};

export default AboutUs;
