import React from 'react';
import "./moral-section.css"
import MoralItem from "./moral-item";
const MoralSection = () => {
    const moralRols = [
        {
            title:"الالتنزام بإرساء المعايير الاخلاقية",
            details:"نلتزم بإرساء معايير أخلاقية وقواعد ومبادئ أساسية لآداب العمل وقيم وثقافة مهنية عالية لدى موظفينا ونعمل على تعزيز الالتزام بالمعايير و القواعد و القيم التالية فـي جميع أعمالنا وأنشطتنا"
        },
        {
            title:"تحري الدقة والمصداقية",
            details:"نقوم بتحري الدقة والمصداقية عند تقديم عرضنا المالي أخذين بالاعتبار طبيعة المهمة الاستشارية وساعات العمل المتوقعة بالإضافة إلى المتطلبات المكملة لإنهاء المشروع بشكل\n" +
                "يرضي العميل"
        },
        {
            title:"المهنية والوصول للجودة العالية",
            details: "تعمل الشركة بمهنية وجودة عالية ولكن لا نقدم الوعود التي لا يمكن تحقيقها، لأننا نعلم أن ذلك سلوك سلبي يؤثر على علاقتنا بعملائنا ويؤدي بكل تأكيد إلى انخفاض مستوى الثقة في مصداقية الشركة",
        },
        // {
        //     title:"انجاز في الوقت المحدد",
        //     details: "نلتزم بإنجاز المشروع في الوقت المحدد مع الاجتهاد في الانتهاء قبل الوقت المحدد",
        // },
        // {
        //     title:"نلتزم باحتياجات العميل",
        //     details: "لا نوصي بالخدمات أو المنتجات التي لا يحتاجها العميل حتى وان خالفت رغبة طالب المشورة ونقوم بتوضيح ذلك لأصحاب القرار لدى العميل إذا دعت الحاجة لذلك",
        // },
        // {
        //     title:"الصدق والامانة",
        //     details: "التحلي بالصدق والصراحة وعدم إيهام العميل بتوقعات غير واقعية تحقيقا لرغبات شخصية",
        // },
        // {
        //     title:"الحفاظ على السرية",
        //     details: "الحفاظ على سرية وسلامة معلومات العملاء واستخدامها في المجالات المخصصة لها فقط وعدم استخدامها للأغراض الشخصية أو السماح للآخرين بالاطلاع عليها",
        // },
        // {
        //     title:"الكشف الفوري عن تضارب المصالح",
        //     details: "تلتزم الشركة بالكشف الفوري عن تضارب المصالح والافصاح بذلك الى العميل لاتخاذ الإجراءات السلامة لحلها مباشرة",
        // },
    ]
    return (
        <div className="moral-section">
            <h1>
                معاييرنا الاخلاقية
                <div className="under-line"></div>
            </h1>
            {moralRols.map((value,i)=>
                <MoralItem title={value.title} details={value.details}/>)}
        </div>
    );
};

export default MoralSection;
