import React from 'react';
import aboutImage from "../../assets/images/about-us.jpeg"
import "./blob-image.css"
const BlobImage = () => {
    return (
        <div className="blob-container">
            <div className="blob-image-bg"></div>
            <div className="blob-image-border-dashed"></div>
            <img className="blob-image" src={aboutImage} alt="about-us"/>
        </div>
    );
};

export default BlobImage;
