import React, {useRef} from 'react';
import "./navbar.css"
import {Link} from "react-router-dom";
const Navbar = () => {
    const navbar = useRef(null)
    const ul = useRef(null)
    window.onscroll = function() {
        if(window.scrollY===0){
            navbar.current.style.backgroundColor = "transparent"
            ul.current.style.backgroundColor = "var(--primary-bg)"
            navbar.current.style.boxShadow = "none"
        } else {
            navbar.current.style.backgroundColor = "#fff"
            ul.current.style.backgroundColor = "#fff"
            navbar.current.style.boxShadow = "1px 1px 4px 0 rgba(0,0,0,.1)"
        }
    }
    const profileLink = "https://protocolqa.com/profiles/%D8%A8%D8%B1%D9%88%D8%AA%D9%88%D9%83%D9%88%D9%84%20%D9%84%D9%84%D8%A7%D8%B3%D8%AA%D8%B4%D8%A7%D8%B1%D8%A7%D8%AA%20%D9%88%D8%A7%D9%84%D8%AA%D8%AF%D8%B1%D9%8A%D8%A8.pdf"
    const whatsappLink = "whatsapp://send?text=&phone=+97474446055"
    return (
        <header ref={navbar} className="navbar">
            <div className="header-icons">
                <Link target="_blank" to={profileLink}>
                    <i className="fa-solid fa-file-arrow-down download-icon"></i>
                </Link>
                <Link target="_blank" to={whatsappLink}>
                    <i className="fa-brands fa-whatsapp icon"></i>
                </Link>
                <Link target="_blank" to="https://www.facebook.com/protocolqa?mibextid=ZbWKwL">
                    <i className="fa-brands fa-facebook icon"></i>
                </Link>
                <Link target="_blank" to="https://instagram.com/protocalqa?igshid=MzRlODBiNWFlZA==">
                    <i className="fa-brands fa-square-instagram icon"></i>
                </Link>
                <Link target="_blank" to="https://www.tiktok.com/@protocolqa?_t=8gEleslnKdS&_r=1">
                    <i className="fa-brands fa-tiktok icon"></i>
                </Link>
            </div>
            <input className="menu-btn" type="checkbox" id="menu-btn"/>
            <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
            <ul ref={ul} className="menu">
                <li><a href="#">الرئيسية</a></li>
                <li><a href="#aboute">عن الشركة</a></li>
                <li><a href="#services">خدماتنا</a></li>
                <li><a href="#why-us">لماذا نحن؟</a></li>
                <li className="download-pdf">
                    <Link className="download-pdf"
                          to={profileLink}>تنزيل البوفايل</Link></li>
            </ul>
        </header>
    );
};

export default Navbar;
