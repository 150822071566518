import React from 'react';
import ServiceItem from "./item/service-item";
import "./services.css"
const Services = () => {
    const services = [
        {
            icon:"fa-solid fa-leaf",
            title: "استشارات عامة",
            details:""
        },
        {
            icon:"fa-solid fa-business-time",
            title: "استشارات تطوير الأعمال",
            details:""
        },
        {
            icon:"fa-solid fa-list-check",
            title: "تنفيذ مشروعات",
            details:""
        },
        {
            icon:"fa fa-file-invoice",
            title: "اعداد دراسات الجدوى",
            details:""
        },
        {
            icon:"fa fa-book",
            title: "ادارة مشروعات",
            details:""
        },
        {
            icon:"fa fa-graduation-cap",
            title: "تدريب وتنمية بشرية",
            details:""
        },
    ]
    return (
        <div id="services" className="container-services">
            <h1>
                خدماتنا
                <div className="under-line"></div>
            </h1>
            <section className="services">
                {services.map((value,index)=> (
                    <ServiceItem icon={value.icon} title={value.title}/>
                ))}
            </section>
        </div>
    );
};

export default Services;
