import './App.css';
import Navbar from "./components/navbar/navbar";
import Header from "./components/header/header";
import AboutUs from "./pages/about-us/about-us";
import OurValues from "./pages/our-values/our-values";
import MoralSection from "./pages/moral-section/moral-section";
import Services from "./pages/services/services";
import WhyUs from "./pages/why-us/why-us";
import Footer from "./components/footer/footer";
import {BrowserRouter} from "react-router-dom";

function App() {
    return (
        <div className="app">
          <div className="container">
              <BrowserRouter>
                  {/*<Whatsapp />*/}
                  <Navbar />
                  <Header />
                  <AboutUs />
                  <OurValues />
                  <MoralSection />
                  <Services />
                  <WhyUs />
                  {/*<ContactUs />*/}
                  <Footer />
              </BrowserRouter>
          </div>
        </div>
      );
}

export default App;
