import React from 'react';
import "./service-item.css"
const ServiceItem = ({icon,title,value}) => {
    return (
        <div className="ag-courses_item">
            <div className="ag-courses-item_link">
                <div className="ag-courses-item_bg"></div>
                <div className="icon"><i className={icon}></i></div>
                <div className="ag-courses-item_title">
                    {title}
                </div>
            </div>
        </div>
    );
};

export default ServiceItem;
